// nativeNetworkDuck.js
// Redux Duck for managing native network

// Action types
const SET_NATIVE_NETWORK = 'nn/SET_NETWORK'; // New action type for setting language
const SET_CURRENT_ADDRESS = 'nn/SET_ADDRESS'; // New action type for setting language


export const CURR_CODE = {
    BNB: "BNB",
    USDT:"BSC",
    ETH:"ETH",
    SOL: "SOL"
}


// Valid ranges for language and speed
export const VALID_NETWORKS = ['bsc', 'eth']; // Example valid codes

export const NETWORK_OTIONS = {
    bsc: {
      img: '/img/wienerdog/icon@bnb1.svg',
      name: "BUY WITH BNB",
      chainId: 56,
      symbol:"bsc"
    },
    eth: {
      img: '/img/wienerdog/ETH.svg',
      name: "BUY WITH ETH",
      chainId: 1,
      symbol:"eth"

    }
  };
export const CURRENCIES = {
    bsc:[
        { text: 'BNB', imageSrc: '/img/wienerdog/icon@bnb1.svg', icon:"/img/wienerdog/icon@bnb1.svg", curr: CURR_CODE.BNB },
        { text: 'USDT', imageSrc: '/img/wienerdog/usdt.svg',  icon:"/img/wienerdog/usdt.svg",curr: CURR_CODE.USDT },
        { text: 'CARD', imageSrc: '/img/wienerdog/card.svg', icon:"/img/wienerdog/usdt.svg", curr: CURR_CODE.USDT },
    ],
    eth:[
        { text: 'ETH', imageSrc: '/img/wienerdog/ETH.svg', icon:"/img/wienerdog/ETH.svg", curr: CURR_CODE.ETH },
        { text: 'USDT', imageSrc: '/img/wienerdog/usdt.svg',  icon:"/img/wienerdog/usdt.svg",curr: CURR_CODE.USDT },
        { text: 'CARD', imageSrc: '/img/wienerdog/card.svg', icon:"/img/wienerdog/usdt.svg", curr: CURR_CODE.USDT },
    ]



}

// Initial state
const initialState = {
    network: 'eth',
    currentAddress: ''
};

// Action creators
export const setNetwork = (network) => {    
    if (VALID_NETWORKS.includes(network)) {
        return {
            type: SET_NATIVE_NETWORK,
            payload: network,
        };
    } else {
      console.error('Invalid language value.');
      return { type: 'INVALID_ACTION' }; // or throw an error
    }
};

export const setCurrentAddress = (address) => {    
    return {
        type: SET_CURRENT_ADDRESS,
        payload: address,
    };
};

// Selectors
// export const selectI18nSection = (sectionKey) => (state) => {
//   const language = state.language.language;
//   return i18nData[language][sectionKey] || {};
// };

export const selectNativeNetworkState = state => state.nativeNetwork.network;
export const selectCurrentAddress = state => state.nativeNetwork.currentAddress;


// Reducer
const nativeNetworkReducer = (state = initialState, action) => {
  switch (action.type) {
        
    case SET_NATIVE_NETWORK:
        return { ...state, network: action.payload };

    case SET_CURRENT_ADDRESS:
      
      return { ...state, currentAddress: action.payload };

    default:
        return state;
  }
};

export default nativeNetworkReducer;
